@font-face {
  font-family: 'Lato';
  src: url("./Font/Lato-Regular.ttf") format('truetype');
  font-size: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'LatoBold';
  src: url('./Font/Lato-Bold.ttf') format('truetype');
  font-size: normal;
  font-weight: normal;
}

button {
  cursor: pointer;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.enlace_desactivado {
  pointer-events: none;
  cursor: default;
}

.div-negro{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-negro-invisible{
  display: none;
}

.invisible{
  display: none;
}

.visible{
  display: block;
}

/*Header*/
.header{
  height: 230px;
}
.header_2{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.img_header{
  width: 240px;
  height: 170px;
}
nav ul a {
  /* width: 115px;
  text-align: center; */
  font-size: 16px;
  font-family: 'Lato';
  color: #0E2C40;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  margin: 0px 5px;
  height: 56px;
  display: flex;
  align-items: center;
}
nav ul a:hover {
  background-color: #FDBB41;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.box_header{
  height: 170px;
}
/*fin - header*/

/*Imagenes*/
.img_index_1{
  background-image: url('../src/Images/escritorio/Index/IE1.png') !important;
  width: 100%;
  min-height: 665px;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 20.5%;
  text-align: center;
}
.img_index_2{
  margin-left: 60px;
}
.img_index_3{
  background-image: url('../src/Images/escritorio/Index/IE3.png') !important;
  width: 100%;
  min-height: 665px;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  display: flex;
  align-items: end;
  justify-content: center;
  flex-direction: column;
  padding-right: 5%;
  text-align: center;
}
.img_index_4{
  height: 292px;
}
.img_nosotros{
  background-image: url('../src/Images/escritorio/Nosotros/NE1.png') !important;
  width: 100%;
  min-height: 439px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_nosotros_2{
  background-image: url('../src/Images/escritorio/Nosotros/NE2.png') !important;
  width: 100%;
  height: 532px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.img_nosotros_3{
  background-image: url('../src/Images/escritorio/Nosotros/NE3.png') !important;
  width: 100%;
  min-height: 530px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_tours{
  background-image: url('../src/Images/escritorio/Tours/TE1.png') !important;
  width: 100%;
  min-height: 439px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_tours_2{
  background-image: url('../src/Images/escritorio/Tours/TE2.png') !important;
  width: 100%;
  height: 314px;
  background-size: 565px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.img_tours_3{
  background-image: url('../src/Images/escritorio/Tours/TE3.png') !important;
  width: 100%;
  height: 314px;
  background-size: 565px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.img_tour_5{
  background-image: url('../src/Images/escritorio/GranCanion/GE11.png') !important;
  width: 246px;
  height: 205px;
  background-size: 365px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.img_gran{
  background-image: url('../src/Images/escritorio/GranCanion/GE1.png') !important;
  width: 100%;
  min-height: 596px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.img_mont{
  background-image: url('../src/Images/escritorio/Montanias/ME8.png') !important;
  width: 100%;
  min-height: 596px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.img_vegas{
  background-image: url('../src/Images/escritorio/Vegas/VE13.png') !important;
  width: 100%;
  min-height: 596px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.img_tour{
  width: 100%; 
  height: 240px;
}
.img_tour_2{
  width: 143px; 
  height: 120px;
  margin-right: 20px;
}
.img_tour_3{
  height: 150px;
}
.img_tour_4{
  width: 246px;
  height: 205px;
  margin-right: 20px;
  float: left;
  /* position: relative;
  right: 120px; */
}
/*fin - Imagenes*/

/*iconos*/
.icon_nosotros{
  width: 117px;
  height: 117px;
}
.icon_tour{
  width: 28px;
  height: 28px;
  margin: 0px 10px;
}
.icon_contactos{
  width: 90px;
  height: 90px;
}
.icon_index_1{
  background-image: url('../src/Images/escritorio/Index/IE7.png') !important;
  width: 100%;
  height: 426px;
  margin-bottom: 30px;
  background-position: center;
  background-repeat: no-repeat;
}
/*fin - iconos*/


/*titulos y textos*/
.titulo_1{
  font-size: 97px;
  font-family: 'LatoBold';
  color: #fff;
  margin: 0%;
  text-shadow: 2px 2px 2px rgba(194,84,52,0.18), 0px 5px 6px rgba(0,0,0,0.5);
}
.titulo_nosotros_2{
  font-size: 114px;
  font-family: 'LatoBold';
  color: #fff;
  margin: 0%;
  text-shadow: 2px 2px 2px rgba(194,84,52,0.18), 0px 5px 6px rgba(0,0,0,0.5);
}
.titulo_tours_1{
  font-size: 40px;
  font-family: 'LatoBold';
  color: #148D8D;
}
.titulo_tours_2{
  font-size: 21px;
  font-family: 'LatoBold';
  color: #1E1E1E;
}
.titulo_2{
  font-size: 40px;
  font-family: 'LatoBold';
  color: #D83951;
  margin-bottom:20px ;
}
.titulo_3{
  font-size: 24px;
  font-family: 'LatoBold';
  color: #1E1E1E;
}
.titulo_5{
  font-size: 24px;
  font-family: 'LatoBold';
}
.titulo_7{
  font-size: 32px;
  font-family: 'LatoBold';
  color: #D83951;
}
.titulo_8{
  font-size: 60px;
  font-family: 'LatoBold';
  color: #000;
}
.titulo_9{
  color: #fff;
  font-size: 81px;
  font-family: 'LatoBold';
  line-height: 80px;
}
.titulo_10{
  font-size: 97px;
  font-family: 'LatoBold';
  color: #fff;
  margin: 0%;
  text-shadow: 2px 2px 2px rgba(194,84,52,0.18), 0px 5px 6px rgba(0,0,0,0.5);
  line-height: 105px;
}

.texto_1{
  font-size: 20px;
  color: #777777;
  font-family: 'Lato';
}
.texto_2{
  font-size: 22px;
  color: #222222;
  font-family: 'LatoBold';
}
.texto_3{
  font-size: 14px;
  font-family: 'Lato';
  color: #1E1E1E;
}
.texto_4{
  font-size: 17px;
  color: #1E1E1E;
  font-family: 'Lato';
}
.texto_5{
  font-size: 21px;
  font-family: 'LatoBold';
  margin-top: 0px;
  color: #148D8D;
}
.texto_6{
  font-size: 20px;
  color: #777777;
  font-family: 'Lato';
}
.texto_7{
  font-size: 32px;
  color: #222222;
  font-family: 'Lato';
}
.texto_contacto{
  font-size: 21px;
  font-family: 'LatoBold';
  margin-top: 0px;
  color: #1E1E1E;
}
/*fin - titulos y textos*/

/*box*/
.box_1{
  order: 1;
  width: 60%;
}
.box_2{
  order: 2;
  width: 35%;
}
.box_3{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 33%;
  margin-bottom: 50px;
}
.box_4{
  line-height: 45px;
  margin-bottom: 30px;
}
.box_5{
  margin: 60px 0px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .box_6{
  width:  330px ;
  margin: 10px;
  height:  292px ;
  margin: 13px ;
  overflow: hidden 
} */
.box_7{
  overflow: hidden 
}
.box_tour{
  display: flex;
  flex-direction: column;
  width: 330px;
  margin: 20px 30px;
  box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.5);
  transition: all 0.5s ease-in-out;
}
.box_tour:hover{
  box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.5);
  transition: all 0.5s ease-in-out;
}

.box_tour_2{
  height: 75px;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}
.box_tour h1{
  text-align: center;
  font-size: 20px;
  font-family: 'LatoBold';
  margin: 0px;
}
.box_tour_3{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box_tour_3 > div > p {
  font-size: 14px;
  font-family: 'Lato';
  margin: 10px 0px;
}
.box_tour_3 > div > p > span {
  font-size: 21px;
  font-family: 'LatoBold';
  color: #D83951;
}
.box_tour_4{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: 'Lato';
  color: #555555;
}
.box_tour_4 > span{
  font-size: 22px;
  color: #148D8D;
  font-family: 'LatoBold';
}
.box_contacto_1{
  order: 1;
}
.box_contacto_2{
  order: 2;
}
.box_intinerario{
  margin-bottom: 80px;
}
.box_intinerario_2{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 50px 0px 0px;
}
.box_index{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 320px;
  margin: 35px;
}
/*fin - box*/

/*caja*/
.caja_tour_contenido{
  text-align: center;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-bottom: solid 2px #CCCCCC;
}
.caja_tour_contenido > div > p{
  font-size: 13px;
  font-family: 'Lato';
  margin: 5px 0px;
}
.caja_tour_contenido > div > span{
  font-size: 13px;
  margin: 5px 0px;
  color: #D83951;
  font-family: 'LatoBold';
}
.caja_inti{
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.caja_2{
  height: 225px;
  padding: 0px 10%;
  margin: 20px 0px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.caja_2 > p {
  font-size: 15px;
  font-family: 'Lato';
  color: #000;
  margin: 10px 0px;
}
.caja_3{
  display: flex;
  justify-content: right;
}
.caja_4{
  border: solid 1px #D4D4D4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 45px 0px;
  margin-top: 0px;
  padding: 26px 0px;
}
.caja_5{
  width: 180px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 30px;
}
.caja_7{
  display: flex;
  justify-content: left;
}
.caja_8{
  width: calc(100% - 178px);
}
.caja_9{
  width: 178px;
  display:flex;
  justify-content:center;
  align-items:center;
}
.caja_10{
  margin: 0px;
}
/*fin -caja*/

/*contenedores*/
.cont_1{
  padding: 0px 5%;
  margin-bottom: 70px;
}
.cont_3{
  margin-bottom: 80px;
}
.cont_4{
  padding: 0px 15%;
  margin-bottom: 70px;
}
.cont_5{
  padding: 0px 10%;
}
.cont_6{
  margin: 50px 0px;
}
.cont_7{
  padding: 0px 12%;
}
.cont_8{
  margin: 50px 0px;
  padding: 0px 27%;
}
.cont_8  .titulo_2 {
  padding: 0px 10%;
}
.cont_9{
  margin-top: 50px;
  padding: 0px 25%;
}
.cont_formulario{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cont_galeria{
  padding: 0px 13% ;
  margin: 50px 0px;
}

/*fin - contenedores*/

/*forma*/
.forma_1{
  height: 1px;
  width: 40%;
  border:solid 1px #CCCCCC;
}
.forma_2{
  width: 243px;
  height: 81px;
  background-color: #FDBB41;
  color: #fff;
  font-size: 47px;
  font-family: 'LatoBold';
  display: flex;
  justify-content: center;
  align-items: center;
}
.forma_3{
  width: 108px;
  height: 108px;
  margin: 0px 35px;
  border: solid 3px #898989;
  border-radius: 50%;
  font-size: 40px;
  font-family: 'LatoBold';
  display: flex;
  justify-content: center;
  align-items: center;
}
.forma_4{
  width: 360px;
  height: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0E2C40;
  font-size: 32px;
  font-family: 'LatoBold';
  letter-spacing: 10px;
  border: solid 1px #0E2C40;
  border-radius: 30px;
  margin: 30px 0px;
}
.linea_completa{
  height: 100%;
  width: 1px;
  background: black;
}
.linea_abajo {
  height: calc(50% - 54px);
  width: 1px;
  background: black;
}
.linea_arriba {
  height: calc(50% - 54px);
  width: 1px;
  background: black;
}
.linea_arriba_inv {
  height: calc(50% - 54px);
  width: 1px;
  background: none;
}
.linea_abajo_inv{
  height: calc(50% - 54px);
  width: 1px;
  background: none;
}

/*fin - forma*/

/*flex*/
.flex_nosotros_1{
  margin-top: 70px;
  margin-bottom: 125px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 5%;
}
.flex_padre_tours{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* padding: 0px 5%; */
  margin-bottom: 170px;
}
.flex_tours{
  width: 30%;
  display: flex;
  flex-direction: column;
  margin: 0px 50px;
  box-shadow: 0px 3px 7px -1px rgba(0,0,0,0.75);
}
.flex_padre_contactos{
  display: flex;
  justify-content:space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 70px;
  margin-bottom: 100px;
}
.flex_contactos{
  width: 220px;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.flex_contactos > .texto_3{
  margin-bottom: 0%;
}
.flex_formulario{
  width: 47%;
}
.flex_formulario_2{
  width: 100%;
}
.flex_formulario_3{
  width: 47%;
}
.flex_formulario > input {
  margin: 25px 0px 50px !important;
  width: 100% !important;
  height: 57px !important;
  border: solid 1px #898989 !important;
}
.flex_formulario_3 > input {
  margin: 25px 0px 50px !important;
  width: 100% !important;
  height: 57px !important;
  border: solid 1px #898989 !important;
}
.flex_formulario_2 > textarea {
  border: solid 1px #898989 !important;
  margin: 25px 0px 50px !important;
  height: 261px !important;
}
.flex_1{
  width: 50%;
  order: 1;
  padding-left: 100px;
}
.flex_2{
  width: 50%;
  order: 2;
}
/*fin - flex*/

/*botones*/
.btn_1{
  width: 413px;
  height: 70px;
  background-color: #FDBB41;
  border-radius: 5px;
  color: #fff;
  font-size: 30px;
  font-family: 'Lato';
  border: none;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;
  justify-content: center;
}
.btn_1:hover{
  box-shadow: 0px 0px 25px #FDBB41;
  transition: all 0.5s ease-in-out;
}
.btn_2{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 242px;
  height: 58px;
  background-color: #D83951;
  font-size: 17px;
  color: #fff;
  font-family: 'LatoBold';
  border: none;
  border-radius: 5px;
  margin: 25px 0px 50px;
  transition: all 0.5s ease-in-out;
}
.btn_2:hover{
  box-shadow: 0px 0px 25px #D83951;
  transition: all 0.5s ease-in-out;
}

.btn_3{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 242px;
  height: 57px;
  font-family: 'LatoBold';
  font-size: 17px;
  color: #fff;
  background-color: #148D8D;
  border: none;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
}
.btn_3:hover{
  box-shadow: 0px 0px 25px #148D8D;
  transition: all 0.5s ease-in-out;
}
.btn_4{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 491px;
  height: 102px;
  font-family: 'LatoBold';
  font-size: 28px;
  color: #fff;
  background-color: #FDBB41;
  border: none;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.btn_4:hover{
  box-shadow: 0px 0px 25px #FDBB41;
  transition: all 0.5s ease-in-out;
}

.btn_5{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 242px;
  height: 57px;
  font-family: 'LatoBold';
  font-size: 17px;
  color: #fff;
  background-color: #D83951;
  border: none;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.btn_5:hover{
  box-shadow: 0px 0px 25px #A80938;
  transition: all 0.5s ease-in-out;
}

.btn_6{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 242px;
  height: 57px;
  font-family: 'LatoBold';
  font-size: 17px;
  color: #fff;
  background-color: #FDBB41;
  border: none;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.btn_6:hover{
  box-shadow: 0px 0px 25px #FDBB41;
  transition: all 0.5s ease-in-out;
}

.btn_7{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 242px;
  height: 57px;
  font-family: 'LatoBold';
  font-size: 17px;
  color: #fff;
  background-color: #0E2C40;
  border: none;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.btn_7:hover{
  box-shadow: 0px 0px 25px #0E2C40;
  transition: all 0.5s ease-in-out;
}

.btn_tour{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 207px;
  background-color: #D83951;
  color: #fff;
  font-size: 13px;
  font-family: 'LatoBold';
  border: none;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  margin: 10px 0px 25px;
}
.btn_tour:hover{
  box-shadow: 0px 0px 25px #D83951;
  transition: all 0.5s ease-in-out;
}

.btn_tours{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 229px;
  height: 57px;
  font-family: 'LatoBold';
  font-size: 17px;
  color: #fff;
  background-color: #148D8D;
  border: none;
  margin: 10px 0px 25px;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
}
.btn_tours:hover{
  box-shadow: 0px 0px 25px #148D8D;
  transition: all 0.5s ease-in-out;
}
/*fin - botones*/


/*footer*/
.page-footer {
  padding: 20px 10% 0px;
  color: #fff;
  background-color: #000;
}
.flex_footer{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.flex_footer_2{
  display: flex;
  align-items: center;
  margin: 10px 0px;
}
.footer_1{
  font-family: 'Lato';
  text-align: right;
  order: 1;
}
.footer_2{
  width: 270px;
  order: 2;
}
.footer_3{
  font-family: 'Lato';
  order: 3;
}
.footer-texto{
  font-size: 18px;
  letter-spacing: 3px;
  margin-bottom: 8px;
  font-family: 'LatoBold';
}
.footer_1 ul a{
  color: #fff;
  font-size: 12px;
}
.footer_1 ul li{
  margin-bottom: 12px;
}
.footer_3 >div > div > p{
  font-size: 12px;
}
.img_footer{
  background-image: url('../src/Images/footer/V2.png') !important;
  width: 100%;
  height: 188px;
  background-size: 270px;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.1s ease-in-out;

}
.img_footer:hover{
  transition: all 0.1s ease-in-out;
  background-image: url('../src/Images/footer/V1.png') !important;
}
.footer_icon{
  width: 42px;
  height: 42px;
  margin: 0px 5px;
}
.footer_icon_2{
  width: 32px;
  height: 32px;
  margin-right: 15px;
}
/*fin - footer*/

/* inicio - carrusel */
.styles-module_carousel-base__3keqD > div{
  display: none;
} 
.styles-module_item-provider__YgMwz{
  display: initial !important;
}
.styles-module_item-container__a8zaY{
  width: 166px !important;
}
.carousel {
  height: 260px;  
  perspective: 1000px;
} 
/* fin - carrusel */

#efecto{
  visibility: hidden;
}

@media only screen and (min-width:200px) and (max-width:850px){ /*----------------Movil------------------*/
  /*header*/
  .header{
    height: 81px;
  }
  .img_header{
    width: 98px;
    height: 70px;
  }
  .box_header{
    height: 81px;
  }
  nav .sidenav-trigger{
    color: #FDBB41;
    border: solid 2px #FDBB41;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin: 0;
    margin-top: 10px;
  }
  /*fin - header*/

  /*imagenes*/
  .img_nosotros{
    background-image: url('../src/Images/movil/Nosotros/NM1.png') !important;
    height: 325px;
  }
  .img_nosotros_2{
    background-image: url('../src/Images/movil/Nosotros/NM2.png') !important;
    height: 370px;
  }
  .img_nosotros_3{
    background-image: url('../src/Images/movil/Nosotros/NM3.png') !important;
    height: 399px;
  }
  .img_tours{
    background-image: url('../src/Images/movil/Tours/TM1.png') !important;
    height: 325px;
  }
  .img_tour{ 
    height: 202px;
  }
  .img_tour_4{
    width: 100%;
    height: 100%;
    margin: 0px;
    margin-bottom: 20px;
  }
  .img_tour_2{
    width: 100%;
    height: 197px;
    margin: 0px;
  }
  .img_tours_2{
    background-image: url('../src/Images/escritorio/Tours/TE2.png') !important;
    width: 100%;
    height: 159px;
    background-size: 310px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .img_tours_3{
    background-image: url('../src/Images/escritorio/Tours/TE3.png') !important;
    width: 100%;
    height: 159px;
    background-size: 310px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .img_gran{
    background-image: url('../src/Images/movil/Tours/GM1.png') !important;
    padding: 0px 5%;
    height: 596px;
    width: 100%;
  }
  .img_mont{
    background-image: url('../src/Images/movil/Tours/MM1.png') !important;
    padding: 0px 5%;
    width: 100%;
    height: 596px;
  }
  .img_vegas{
    background-image: url('../src/Images/movil/Tours/VM1.png') !important;
    padding: 0px 5%;
    width: 100%;
    height: 596px;
  }
  .img_index_2{
    width: 100%;
    /* height: 239px; */
    margin-left: 0px;
  }
  .img_index_3{
    background-image: url('../src/Images/movil/Index/IM3.png') !important;
    width: 100%;
    height: 450px;
    padding: 0px 60px;
  }
  .img_index_4{
    height: 130PX;
  }
  .img_index_1{
    background-image: url('../src/Images/movil/Index/IM1.png') !important;
    width: 100%;
    height: 577px;
    padding: 0px 60px;
  }
  .icon_index_1{
    background-image: url('../src/Images/movil/Index/IM2.png') !important;
    width: 326px;
    height: 146px;
    margin: 30px 0px;
  }
  /*fin - imagenes*/
  
  /*titulos*/
  .titulo_tours_1{
    font-size: 25px;
    padding: 0px 20%;
  }
  .titulo_tours_2{
    font-size: 17px;
    margin: 12px 0px;
  }
  .titulo_1{
    font-size: 53px;
    text-align: center;
  }
  .titulo_nosotros_2{
    font-size: 53px;
    text-align: center;
  }
  .texto_contacto{
    font-size: 17px;
  }
  .titulo_2{
    font-size: 25px;
  }
  .titulo_3{
    font-size: 25px;
  }
  .titulo_7{
    font-size: 25px;
    text-align: center;
  }
  .titulo_9{
    font-size: 31px;
    line-height: 40px;
  }
  .titulo_10{
    font-size: 42px;
    text-align: center;
    line-height: 50px;
  }
  .texto_5{
    font-size: 21px;
    font-family: 'LatoBold';
    margin-top: 215px;
    color: #148D8D;
  }
  .texto_6{
    font-size: 17px;
  }
  .texto_7{
    font-size: 17px;
    color: #222222;
    font-family: 'Lato';
  }
  /*fin - titulos*/

  /*box*/
  .box_tour h1{
    font-size: 17px;
  }
  .box_tour{
    width: 275px;
  }
  .box_tour_2{
    height: 66px;
  }
  .box_tour_3 > div > p {
    font-size: 11px;
    font-family: 'Lato';
    margin: 10px 0px;
  }
  .box_tour_3 > div > p > span {
    font-size: 17px;
    font-family: 'LatoBold';
    color: #D83951;
  }
  .box_tour_4{
    margin: 10px 0px 0px;
    font-size: 11px;
  }
  .box_tour_4 > span{
    font-size: 17px;
  }
  .box_contacto_1{
    order:2;
  }
  .box_contacto_2{
    order: 1;
  }
  .box_1{
    order: 2;
    width: 100%;
  }
  .box_2{
    order: 1;
    width: 100%;
    margin-bottom: 25px;
    text-align: center;
    padding: 0px 12%;
  }
  .box_3{
    width: 100%;
  }
  .box_4{
    text-align: center;
    line-height: 35px;
    margin-bottom: 20px;
  }
  .box_5{
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box_6{
    width:  146px ;
  }
  .box_intinerario_2{
    width: 100%;
    margin-top: 20px;
  }
  /* .box_index{
    margin: 10px 35px;
  } */
  /*fin -box*/
  
  /*caja*/
  .caja_1{
    display: flex;
    justify-content: center;
  }
  .caja_2{
    height: 240px;
    padding: 0px 11%;
    margin: 10px 0px;
  }
  .caja_2 > p {
    font-size: 12px;
  }
  .caja_tour_contenido{
    height: 180px;
  }
  .caja_3{
    justify-content: center;
  }
  .caja_4{
    margin: 20px 0px;
    padding: 0px;
  }
  .caja_inti{
    text-align: center;
    flex-direction: column;
    margin-bottom: 40px;
  }
  .caja_5{
    margin: 15px 30px;
  }
  .caja_6{
    text-align: center;
    margin-bottom: 60px;
  }
  .caja_7{
    flex-direction: column;
    text-align: center;
  }
  .caja_7 > div > p{
    padding: 20px 10% 0px;
  }
  .caja_8{
    width: 100%;
  }
  .caja_9{
    width: 0px;
  }
  .caja_10{
    margin: 30px;
    padding:0px 10%;
  }
  /*fin - caja*/
  
  /*contenedores*/
  .cont_3{
    padding: 0px 15%;
    margin-bottom: 30px;
  }
  .cont_4{
    padding: 0px 10%;
    margin-bottom: 50px;
  }
  .cont_7{
    padding: 2%;
  }
  .cont_8{
    padding: 0px 10%;
    text-align: center;
  }
  .cont_9{
    padding: 0px 17%;
  }
  .cont_10{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    padding-bottom: 20px;
  }
  .cont_10 .titulo_9{
    margin-top: 15px;
  }
  .cont_galeria{
    padding: 0px 5%;
  }
  /*fin - contenedores*/

  /*forma*/
  .forma_2{
    width: 129px;
    height: 43px;
    font-size: 24px;
  }
  .forma_4{
    width: 245px;
    height: 65px;
    font-size: 25px;
  }

  .linea_completa{
    height: 0%;
    width: 1px;
    background: none;
  }
  .linea_abajo {
    height: 0px;
    width: 0px;
    background: none;
  }
  .linea_arriba {
    height: 0px;
    width: 0px;
    background: none;
  }
  .linea_arriba_inv {
    height: 0px;
    width: 0px;
    background: none;
  }
  .linea_abajo_inv{
    height: 0px;
    width: 0px;
    background: none;
  }
  /*fin - forma*/


  /*flex*/
  .flex_nosotros_1{
    padding: 0%;
    margin-top: 5px;
  }
  .flex_tours{
    width: 305px;
    display: flex;
    flex-direction: column;
    margin: 0px 20px 50px;
  }
  .flex_padre_tours{
    margin-bottom: 20px;
  }
  .flex_padre_contactos{
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .flex_contactos > .texto_3{
    font-size: 17px;
  }
  .flex_contactos{
    margin-bottom: 35px;
  }
  .flex_contacto{
    display: grid;
  }
  .flex_formulario{
    width: 100%;
  }
  .flex_formulario_3{
    width: 100%;
  }
  .flex_formulario > input {
    margin: 10px 0px 25px !important;
    width: 100% !important;
    height: 50px !important;
    border: solid 1px #898989 !important;
  }
  .flex_formulario_3 > input {
    margin: 10px 0px 25px !important;
    width: 100% !important;
    height: 50px !important;
    border: solid 1px #898989 !important;
  }
  .flex_formulario_2 > textarea {
    border: solid 1px #898989 !important;
    margin: 10px 0px 25px !important;
    height: 176px !important;
  }
  .flex_1{
    width: 100%;
    order: 2;
    padding-left: 0px;
  }
  .flex_2{
    width: 100%;
    order: 1;
  }
  /*fin - flex*/

  /*boton*/
  .btn_1{
    width: 259px;
    height: 57px;
    font-size: 17px;
  }
  .btn_2{
    width: 259px;
    height: 57px;
  }
  .btn_4{
    width: 259px;
    height: 57px;
    font-size: 17px;
  }
  .btn_tour{
    width: 174px;
    height: 38px;
    font-size: 15px;
    margin: 10px 0px 15px;
  }
  .btn_tours{
    width: 216px;
    height: 38px;
    font-size: 15px;
  }
  /*fin - boton*/

  /*footer*/
  .flex_footer{
    flex-direction: column;
  }
  .flex_footer_2{
    flex-direction: column;
    margin: 15px 0px;
  }
  .footer_1{
    order: 2;
    text-align: center;
  }
  .footer_2{
    order: 1;
  }
  .footer_3{
    text-align: center;
    order: 3;
  }
  .footer_icon_2{
    margin: 0;
  }
  .footer-texto{
    font-size: 16px;
  }
  .footer_1 ul li{
    margin-bottom: 18px;
  }
  /*fin - footer*/

}

@media only screen and (min-width:551px) and (max-width:850px){ /*----------------Tablet------------------*/
   /*header*/
   .header{
    height: 81px;
  }
  .img_header{
    width: 98px;
    height: 70px;
  }
  .box_header{
    height: 81px;
  }
  nav .sidenav-trigger{
    color: #FDBB41;
    border: solid 2px #FDBB41;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin: 0;
    margin-top: 10px;
  }
  /*fin - header*/


  /*box*/
  .box_1{
    order: 2;
    width: 100%;
  }
  .box_2{
    order: 1;
    width: 100%;
  }
  .box_3{
    width: 100%;
  }
  /*fin -box*/

  /*footer*/
  .flex_footer{
    flex-direction: column;
  }
  .flex_footer_2{
    flex-direction: column;
    margin: 15px 0px;
  }
  .footer_1{
    order: 2;
    text-align: center;
  }
  .footer_2{
    order: 1;
  }
  .footer_3{
    text-align: center;
    order: 3;
  }
  .footer_icon_2{
    margin: 0;
  }
  .footer-texto{
    font-size: 16px;
  }
  .footer_1 ul li{
    margin-bottom: 18px;
  }
  /*fin - footer*/

  .img_index_1{
    background-image: url('../src/Images/escritorio/Index/IE1.png') !important;
    width: 100%;
    height: 577px;
    padding: 0px 60px;
  }
  .img_index_3{
    background-image: url('../src/Images/escritorio/Index/IE3.png') !important;
    width: 100%;
    height: 577px;
    padding: 0px 60px;
    background-position-x: -975px;
  }
  .img_nosotros{
    background-image: url('../src/Images/escritorio/Nosotros/NE1.png') !important;
    width: 100%;
    height: 439px;
  }
  .img_nosotros_2{
    background-image: url('../src/Images/escritorio/Nosotros/NE2.png') !important;
    width: 100%;
    height: 532px;
  }
  .img_nosotros_3{
    background-image: url('../src/Images/escritorio/Nosotros/NE3.png') !important;
    width: 100%;
    height: 530px;
  }
  .img_tours{
    background-image: url('../src/Images/escritorio/Tours/TE1.png') !important;
    width: 100%;
    height: 439px;
  }
  .img_gran{
    background-image: url('../src/Images/escritorio/GranCanion/GE1.png') !important;
    width: 100%;
    height: 596px;
  }
  .img_mont{
    background-image: url('../src/Images/escritorio/Montanias/ME8.png') !important;
    width: 100%;
    height: 596px;
  }
  .img_vegas{
    background-image: url('../src/Images/escritorio/Vegas/VE13.png') !important;
    width: 100%;
    height: 596px;
  }

}

@media only screen and (min-width:851px) and (max-width:1024px){/*---------------Pantalla chica---------------*/
  /* imagen */
  .img_index_1{
    padding: 0px 10%;
  }
  .img_index_3{
    padding-right: 2%;
  }
  .img_index_4{
    height: 250px;
  }
  .img_tour_2{
    margin-top: 12px;
  }
  /* fin - imagen */

  /*boton*/
  .btn_1{
    width: 330px;
    font-size: 25px;
  }
  /*fin - boton*/

  /*flex*/
  .flex_nosotros_1{
    margin-top: 35px;
    align-items: center;
  }
  /*fin - flex*/

  /* titulos */
  .titulo_9{
    font-size: 60px;
    line-height: 65px;
  }
  .titulo_10{
    font-size: 75px;
    line-height: 80px;
  }
  /* fin - titulos */

  /*caja*/
  .caja_2 > p{
    font-size: 13px;
  }
  /*fin - caja*/
  /*contenedores*/
  .cont_2{
    margin-top: 50px;
  }
  .cont_galeria{
    padding: 0px;
  }
  .cont_formulario{
    justify-content: space-evenly;
  }
  /*fin - contenedores*/
  /* box */
  .box_6{
    width: 300px;
    margin: 10px;
  }
  .box_tour{
    width: 300px;
    margin: 20px 10px;
  }
  /* fin - box */
  /* flex */
  .flex_1{
    padding-left: 0px;
  }
  .flex_formulario{
    width: 45%;
  }
  .flex_formulario_2{
    width: 95%;
  }
  .flex_formulario_3{
    width: 95%;
  }
  /* fin - flex */

}

@media only screen and (min-width:1025px) and (max-width:1664px){ /*----------------Pantalla grandre-----------------------*/
  /* imagenes */
  .img_index_1{
    padding: 0px 12%;
  }
  .img_tour_2{
    width: 143px; 
    height: 120px;
    margin-right: 20px;
    margin-top: 12px;
  }
  /* fin - imagenes */
  
  
  /*contenedores*/
  .cont_1{
    padding: 0px 15%;
    margin-bottom: 70px;
  }
  .cont_2{
    margin-top: 50px;
  }
  .cont_5{
    padding: 0px 20%;
  }
  .cont_7{
    padding: 0px 19%;
  }
  .cont_galeria{
    padding: 0px 11%;
    margin: 50px 0px;
  }
  /*fin - contenedores*/
  
  /* box */
  .box_tour{
    margin: 20px 20px;
  }

  .box_6{
    width: 330px;
    margin: 10px;
  }
  /* fin - box */

  /*flex*/
  .flex_nosotros_1{
    padding: 0px 15%;
  }
  .flex_formulario{
    width: 31%;
  }
  .flex_formulario_3{
    width: 31%;
  }
  .flex_padre_contactos{
    padding: 0px 5%;
  }
  /*fin - flex*/
}

@media only screen and (min-width:1665px){ /*----------------Pantalla muy grande-----------------------*/
  /*contenedores*/
  .cont_1{
    padding: 0px 15%;
    margin-bottom: 70px;
  }
  .cont_2{
    margin-top: 50px;
  }
  .cont_5{
    padding: 0px 20%;
  }
  .cont_7{
    padding: 0px 19%;
  }
  .cont_galeria{
    padding: 0px 16%;
    margin: 50px 0px;
  }
  
  /*fin - contenedores*/


  /* box */
  .box_6{
    width: 330px;
    margin: 10px;
  }
  /* fin - box */


 
  /*flex*/
  .flex_nosotros_1{
    padding: 0px 15%;
  }
  .flex_formulario{
    width: 31%;
  }
  .flex_formulario_3{
    width: 31%;
  }
  .flex_padre_contactos{
    padding: 0px 5%;
  }
  /*fin - flex*/
}